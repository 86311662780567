import React from "react";
import { Link, graphql } from "gatsby";
import { MdArrowForward, MdArrowBack } from "react-icons/md";
import Layout from "../Layout/Layout";
import BlogPostSmall from "../modules/Blog/post-small";
import SEO from "../Layout/Seo";
import BreadCrumb from "../modules/Breadcrumb/BreadCrumb";
import CategoriesContainer from "../modules/Blog/CategoriesContainer";
import SearchContainer from "../modules/Search/SearchContainer";
import codeImage from "../images/blog/computer-code-on-screen-ZP4TU27.png";
import CTA from "../modules/CTA/CTA";

const PageBlogTemplate = ({ location, ...props }) => {
  const { currentPage } = props.data;
  const posts = props.data.posts.edges;
  const metaTitle = currentPage.yoast.title || "";
  const metaDesc = currentPage.yoast.metadesc || "";
  const metaKeyWords = currentPage.yoast.metakeywords || "";

  const items = [{ to: "/", label: "Home" }, { label: "Blog" }];

  const currentDescription = posts[0].node.excerpt;
  const cleanExcerpt = currentDescription.replace(/<\/?[^>]+(>|$)/g, "");
  // eslint-disable-next-line no-unused-vars
  const changeEnding = cleanExcerpt.replace(/(\s\[&hellip;])/g, "...");

  return (
    <Layout>
      <SEO title={metaTitle} keywords={metaKeyWords} description={metaDesc} />
      <section
        className="blog head"
        style={{
          backgroundImage: `url("${codeImage}")`
        }}
      >
        <div className="head__overlay" />
        <BreadCrumb crumbs={items} />
        <h1 className="section__title--large head__title">Blog</h1>
      </section>
      <div className="blog__menu">
        <div className="container">
          <div className="categories__menu">
            <div className="categories__left">
              <CategoriesContainer props={props.data.menu.items} />
            </div>
            <div className="categories__right">
              <div className="search">
                <SearchContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog__main-wrapper">
        <section className="blog">
          <div className="container">
            <section className="blog__content">
              <div className="blog__list">
                {posts.map((option, i) => (
                  <BlogPostSmall
                    id={option.node.id}
                    position={i}
                    key={option.node.id}
                    link={option.node.path}
                    excerpt={option.node.excerpt.replace(/(\s\[&hellip;])/g, "...")}
                    author={option.node.author}
                    title={option.node.title}
                    img={
                      option.node.featured_media &&
                      option.node.featured_media.localFile.childImageSharp.original.src
                    }
                    tags={option.node.tags}
                    date={option.node.date}
                    category={
                      typeof option.node.categories !== "undefined"
                        ? option.node.categories[0]
                        : null
                    }
                  />
                ))}
                <div className="button__wrapper">
                  <div className="pagination__wrapper">
                    {props.pageContext.numberOfPages && (
                      <div className="pagination">
                        {props.pageContext.previousPagePath && (
                          <Link to={props.pageContext.previousPagePath} className="arrow">
                            <MdArrowBack size={20} />
                            <span>newer posts</span>
                          </Link>
                        )}
                        {[...Array(props.pageContext.numberOfPages)].map((e, i) => {
                          return i === props.pageContext.pageNumber ? (
                            <span className="current">{i + 1}</span>
                          ) : (
                            <Link to={i === 0 ? "/blog" : `/blog/${i + 1}`} className="inactive">
                              {i + 1}
                            </Link>
                          );
                        })}
                        {props.pageContext.nextPagePath && (
                          <Link to={props.pageContext.nextPagePath} className="arrow">
                            <span>older posts</span>
                            <MdArrowForward size={20} />
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <CTA message="Let's talk about your Magento project!" />
      </div>
    </Layout>
  );
};

export default PageBlogTemplate;

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $id: String!) {
    posts: allWordpressPost(sort: { fields: [date], order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          author {
            name
            link
            avatar_urls {
              wordpress_48
            }
          }
          categories {
            id
            name
            slug
          }
          date(formatString: "DD MMMM YYYY")
          featured_media {
            source_url
          }
          tags {
            id
            name
            path
          }
          title
          path
          excerpt
          content
          featured_media {
            id
            link
            source_url
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
    currentPage: wordpressPage(id: { eq: $id }) {
      id
      title
      content
      yoast {
        title
        metakeywords
        metadesc
      }
    }
    menu: wordpressWpApiMenusMenusItems(slug: { eq: "categories-menu" }) {
      items {
        title
        object_slug
        wordpress_children {
          title
          object_id
          object_slug
        }
        url
      }
    }
  }
`;
