import React from "react";

const CTANewsletter = ({ message = "Want to talk about your project?" }) => {
  return (
    <section className="main-cta">
      <div className="container">
        <div className="section__header">
          <div className="section__title section__title--contact">{message}</div>
        </div>
        <div className="section__button">
          <div className="cta__content cta__content--what-we-can-do">
            <div className="join-us-button">
              <a href="/contact">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <div className="btn__cta btn__cta--green btn__cta--contactus">Let's talk!</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTANewsletter;
